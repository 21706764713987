<template>
  <div>
    <div style="display: flex; padding: 0px 0; align-items: center">
      <el-select
        v-if="false"
        clearable
        v-model="form2.Year"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        clearable
        v-model="form2.Month"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        clearable
        v-model="form2.Week"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <!-- <span style="margin-right: 10px">至</span>
      <el-select
        v-model="form2.endYear"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endMonth"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endWeek"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select> -->

      <span>门店: </span>
      <el-select
        clearable
        v-model="form2.StroeId"
        style="margin-left: 10px"
        placeholder="请选择门店"
      >
        <el-option
          v-for="item in AllDoorData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <span style="margin-left: 20px">教师: </span>

      <el-input
        style="width: 200px; margin-left: 10px"
        v-model="form2.TeachId"
        placeholder="请输入教师"
      />

      <el-select
        clearable
        v-model="form2.type"
        style="margin-left: 20px"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in PlanTypeData"
          :key="item.Id"
          :label="item.label"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="SearchAllExamineLimitPageFn"
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
        @click="resettingFn"
      >
        重置
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px">
      <el-table
        :data="tableData"
        :header-cell-style="getRowClass"
        style="width: 100%; background-color: #fff"
        :row-style="{ height: '60px' }"
      >
        <el-table-column label="序号" prop="CardId">
          <template slot-scope="scope">
            <span>{{ (pageindex - 1) * pagesize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="课程名称" prop="WorkName" /> -->
        <el-table-column label="分类名称" prop="WorkTypeName">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 1">基础课</span>
            <span v-if="scope.row.WorkType == 2">专项课</span>
            <span v-if="scope.row.WorkType == 3">试听课</span>
          </template> -->
        </el-table-column>
        <!-- <el-table-column label="学生批次" prop="CardId" /> -->
        <el-table-column label="门店" prop="StoreName" />
        <el-table-column label="课室" prop="StoreRoomName" />
        <el-table-column label="教师名称" prop="TeacherName" />
        <el-table-column label="课程教案" prop="TeachPlanName" />
        <!-- <el-table-column label="课室" prop="CardNum" /> -->
        <el-table-column label="上课时间" prop="WorkTime" />
        <!-- <el-table-column label="课程状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 0">未审批</span>
            <span v-if="scope.row.WorkType == 1">已通过</span>
            <span v-if="scope.row.WorkType == 2">未通过</span>
          </template>
        </el-table-column> -->
        <el-table-column label="报名学生" prop="StuNum" />
        <el-table-column label="审批状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.Status == 0">未审批</span>
            <span v-if="scope.row.Status == 1">已通过</span>
            <span v-if="scope.row.Status == 2">未通过</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <!-- <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deleteKeys"
            > -->
            <span
              v-if="scope.row.Status == 0"
              @click="UpdateExamineTrueFn(scope.row)"
              style="font-size: 14px; color: #007aff; cursor: pointer"
              >审核通过</span
            >
            <span
              v-if="scope.row.Status == 0"
              @click="openDialogVisible2(scope.row)"
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 10px;
              "
              >驳回</span
            >

            <span
              v-if="scope.row.Status == 2"
              @click="openDialogVisible3(scope.row)"
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 0px;
              "
              >驳回理由</span
            >

            <!-- <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button> -->
            <!-- </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>

      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageindex"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="AllCount"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 修改人数 -->
    <el-dialog title="学生人数" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              旧人数
            </div>
            <el-input style="flex: 1" placeholder="请输入" />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              新人数
            </div>
            <el-input style="flex: 1" placeholder="请输入" />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 驳回人数 -->
    <el-dialog title="驳回理由" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div style="display: flex; width: 100%; align-items: center">
          <div style="font-size: 16px; font-size: 16px; width: 80px">
            驳回理由
          </div>
          <el-input v-model="form.Cause" style="flex: 1" placeholder="请输入" />
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offDialogVisible2"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateExamineFalseFn(FalseItem)"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 驳回理由 -->
    <el-dialog title="驳回理由" :visible.sync="dialogVisible3" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div
          style="
            width: 100%;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            line-height: 20px;
          "
        >
          {{ form.Cause2 }}
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <!-- <div
            @click="offDialogVisible3"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            确定
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  DelTrans,
  GetUrl,
  AddTrans,
  GetBuyAgentCode,
  GetVNCNetWorkLinks, //获取vnc网络链接
  GetAllTrans,
  UpdateTrans,
  GetUserInfo,
  GetPrice,
  GetNetworkLinks,
  useCardSecret,
  GetlocalDeployment,
  GettheAgentTestLink,
  GetVNCAppDwonUrl,
  GetVNCToken,
  GetAllExamineLimitPage, //分页获取所有审批信息
  GetOneExamineLimitPage,
  UpdateExamine, //审批
  SearchAllExamineLimitPage, //搜索
  GetAllStore,
  GetAllTeachPlan,
  SearchOwnExamineLimitPage,
} from "@/request/api";
import axios from "axios";

export default {
  inject: ["reload"],
  name: "Agent",
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      pageindex: 1,
      pagesize: 10,
      tableData: [],
      AllCount: 0,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      form: {
        Cause: "",
      },
      form2: {
        Year: "",
        Month: "",
        Week: "",
        StroeId: "",
        TeachId: "",
        // endYear: "",
        // endMonth: "",
        // endWeek: "",
        type: "",
        searchDate: false,
      },
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      YearData: [],
      MonthData: [],
      WorkTypeData: [
        {
          Id: 1,
          Name: "基础课",
        },
        {
          Id: 2,
          Name: "专项课",
        },
        {
          Id: 3,
          Name: "试听课",
        },
      ],
      searchCheck: false, //
      AllDoorData: [],
      PlanTypeData: [],
    };
  },

  watch: {
    "form2.type"() {
      this.SearchAllExamineLimitPageFn();
    },
    "form2.StroeId"() {
      this.SearchAllExamineLimitPageFn();
    },
  },
  methods: {
    // 教案

    //获取所有分类
    GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan()
        .then((res) => {
          console.log(res);
          if (res.Code == 2000) {
            console.log("获取所有教案分类==>", res.Data);
            // that.teachData = res.Data;
            that.PlanTypeData = res.Data;
          }
        })
        .catch((err) => {
          console.log("====================================");
          console.log(err);
          console.log("====================================");
        });
    },

    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      // return "background:#50a0ff;color:#fff;font-size:16px;color:#fff";
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      if (this.searchCheck == true) {
        this.SearchAllExamineLimitPageFn();
      } else {
        this.GetAllExamineLimitPageFn();
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageindex = val;
      if (this.searchCheck == true) {
        this.SearchAllExamineLimitPageFn();
      } else {
        this.GetAllExamineLimitPageFn();
      }
    },

    // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore()
        .then((res) => {
          console.log(res);
          if (res.Code == 2000) {
            console.log("获取所有门店==>", res.Data);
            that.AllDoorData = res.Data;
          } else {
            this.$message.error(res.Message);
          }
        })
        .catch((err) => {
          console.log("====================================");
          console.log(err);
          console.log("====================================");
        });
    },

    //通过审核
    UpdateExamineTrueFn(row) {
      let that = this;
      that
        .$confirm("确定通过该课程信息审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          UpdateExamine({
            id: row.Id,
            isPass: true,
            Cause: "已通过",
          }).then((res) => {
            console.log(" 管理员通过==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "已通过",
              });
              that.GetAllExamineLimitPageFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //审核驳回
    UpdateExamineFalseFn(row) {
      let that = this;
      if (that.form.Cause == "") {
        that.$message({
          type: "error",
          message: "请填写驳回理由",
        });
        return;
      }
      that
        .$confirm("确定驳回该课程信息审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          UpdateExamine({
            id: row.Id,
            isPass: false,
            Cause: that.form.Cause,
          }).then((res) => {
            console.log(" 管理员驳回==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "已驳回",
              });
              that.dialogVisible2 = false;
              that.form.Cause = "";
              that.GetAllExamineLimitPageFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //打开驳回弹窗
    openDialogVisible2(row) {
      this.FalseItem = row;
      this.dialogVisible2 = true;
    },
    //打开驳回弹窗
    offDialogVisible2() {
      this.dialogVisible2 = false;
    },

    //打开驳回理由
    openDialogVisible3(row) {
      this.form.Cause2 = row.Cause;
      this.dialogVisible3 = true;
    },
    offDialogVisible3(row) {
      this.form.Cause2 = "";
      this.dialogVisible3 = false;
    },

    //审批列表搜索
    SearchAllExamineLimitPageFn() {
      let that = this;

      console.log("审批列表搜索==>", that.form2);

      if (
        that.form2.Year == "" &&
        that.form2.Month == "" &&
        that.form2.Week == ""
      ) {
        // that.$message({
        //   message: "请选择完整时间",
        //   type: "warning",
        // });
        that.form2.searchDate = false;
      }

      // if(that.form2.Year==''||that.form2.Month==''||that.form2.Week==''){
      //   // that.$message({
      //   //   message: "请选择完整时间",
      //   //   type: "warning",
      //   // });
      //   that.form2.searchDate = false;
      // }

      if (
        that.form2.Year != "" &&
        that.form2.Month != "" &&
        that.form2.Week != ""
      ) {
        that.form2.searchDate = true;
      }

      that.pageindex = 1;
      that.pagesize = 10;

      const tokenDatas = JSON.parse(localStorage.getItem("TokenData"));
      console.log(tokenDatas);
      if (tokenDatas.Role === "admin") {
        SearchAllExamineLimitPage({
          Year: that.form2.Year === "" ? -1 : that.form2.Year,
          // Year: that.form2.Year==''&& that.form2.Year!=0 ?'-1':that.form2.Year,
          Month: that.form2.Month == "" ? -1 : that.form2.Month,
          Week: that.form2.Week == "" ? -1 : that.form2.Week,
          // endYear: that.form2.endYear,
          // endMonth: that.form2.endMonth,
          // endWeek: that.form2.endWeek,
          TeachName: that.form2.TeachId,
          storeId: that.form2.StroeId == "" ? -1 : that.form2.StroeId,
          searchDate: that.form2.searchDate,
          type: that.form2.type == "" ? -1 : that.form2.type,
          pageindex: that.pageindex,
          pagesize: that.pagesize,
        })
          .then((res) => {
            console.log(res);
            if (res.Code == 2000) {
              console.log("审批列表搜索==>", res.Data.Data);
              console.log("审批列表搜索数量==>", res.Data.AllCount);
              that.searchCheck = true;

              // for (let i = 0; i < res.Data.Data.length; i++) {
              //   const element = res.Data.Data[i];
              //   console.log("element==>", element);
              //   for (let j = 0; j < that.AllDoorData.length; j++) {
              //     const element2 = that.AllDoorData[j];
              //     console.log("element2==>", element2);
              //     element.Between =
              //       element.StartYear +
              //       "岁" +
              //       element.StartMonth +
              //       "月" +
              //       element.StartWeek +
              //       "周" +
              //       "-" +
              //       element.EndYear +
              //       "年" +
              //       element.EndMonth +
              //       "月" +
              //       element.EndWeek +
              //       "周";
              //     if (element.StroeId == element2.Id) {
              //       // console.log("等");
              //       element.StroeName = element2.Name;
              //     }
              //   }
              // }

              that.tableData = res.Data.Data;
              that.AllCount = res.Data.AllCount;
            } else {
              that.$message.error(res.Message);
            }
          })
          .catch((err) => {
            console.log("====================================");
            console.log(err);
            console.log("====================================");
          });
      } else if (tokenDatas.Role === "teacher") {
        SearchOwnExamineLimitPage({
          Year: that.form2.Year === "" ? -1 : that.form2.Year,
          // Year: that.form2.Year==''&& that.form2.Year!=0 ?'-1':that.form2.Year,
          Month: that.form2.Month == "" ? -1 : that.form2.Month,
          Week: that.form2.Week == "" ? -1 : that.form2.Week,
          // endYear: that.form2.endYear,
          // endMonth: that.form2.endMonth,
          // endWeek: that.form2.endWeek,
          TeachName: that.form2.TeachId,
          storeId: that.form2.StroeId == "" ? -1 : that.form2.StroeId,
          searchDate: that.form2.searchDate,
          type: that.form2.type == "" ? -1 : that.form2.type,
          pageindex: that.pageindex,
          pagesize: that.pagesize,
        })
          .then((res) => {
            console.log(res);
            if (res.Code == 2000) {
              console.log("审批列表搜索==>", res.Data.Data);
              console.log("审批列表搜索数量==>", res.Data.AllCount);
              that.searchCheck = true;

              // for (let i = 0; i < res.Data.Data.length; i++) {
              //   const element = res.Data.Data[i];
              //   console.log("element==>", element);
              //   for (let j = 0; j < that.AllDoorData.length; j++) {
              //     const element2 = that.AllDoorData[j];
              //     console.log("element2==>", element2);
              //     element.Between =
              //       element.StartYear +
              //       "岁" +
              //       element.StartMonth +
              //       "月" +
              //       element.StartWeek +
              //       "周" +
              //       "-" +
              //       element.EndYear +
              //       "年" +
              //       element.EndMonth +
              //       "月" +
              //       element.EndWeek +
              //       "周";
              //     if (element.StroeId == element2.Id) {
              //       // console.log("等");
              //       element.StroeName = element2.Name;
              //     }
              //   }
              // }

              that.tableData = res.Data.Data;
              that.AllCount = res.Data.AllCount;
            } else {
              that.$message.error(res.Message);
            }
          })
          .catch((err) => {
            console.log("====================================");
            console.log(err);
            console.log("====================================");
          });
      }
    },

    //搜索重置
    resettingFn() {
      (this.form2 = {
        Year: "",
        Month: "",
        Week: "",
        TeachId: "",
        StroeId: "",
        searchDate: false,
        type: "",
      }),
        this.searchCheck == false;
      this.pageindex = 1;
      this.pagesize = 10;
      this.GetAllExamineLimitPageFn();
    },

    // 分页获取所有审批信息
    GetAllExamineLimitPageFn() {
      let that = this;
      const tokenDatas = JSON.parse(localStorage.getItem("TokenData"));
      console.log(tokenDatas);
      if (tokenDatas.Role === "admin") {
        GetAllExamineLimitPage({
          pageindex: that.pageindex,
          pagesize: that.pagesize,
        })
          .then((res) => {
            if (res.Code == 2000) {
              console.log("分页获取所有审批信息==>", res.Data.Data);
              that.searchCheck = false;
              for (let i = 0; i < res.Data.Data.length; i++) {
                const element = res.Data.Data[i];
                element.WorkTime =
                  element.WorkTime.split(" ")[0] +
                  " " +
                  String(
                    String(
                      element.StartHour < 10
                        ? "0" + element.StartHour
                        : element.StartHour
                    ) +
                      ":" +
                      String(
                        element.StartMinute < 10
                          ? "0" + element.StartMinute
                          : element.StartMinute
                      ) +
                      "-" +
                      String(
                        element.EndHour < 10
                          ? "0" + element.EndHour
                          : element.EndHour
                      ) +
                      ":" +
                      String(
                        element.EndMinute < 10
                          ? "0" + element.EndMinute
                          : element.EndMinute
                      )
                  );
              }
              if (that.pageindex == 1) {
                that.AllCount = res.Data.AllCount;
              }
              that.tableData = res.Data.Data;
            }
          })
          .catch((err) => {
            console.log("====================================");
            console.log(err);
            console.log("====================================");
          });
      } else if (tokenDatas.Role === "teacher") {
        GetOneExamineLimitPage({
          pageindex: that.pageindex,
          pagesize: that.pagesize,
        })
          .then((res) => {
            if (res.Code == 2000) {
              console.log("分页获取所有审批信息==>", res.Data.Data);
              that.searchCheck = false;
              for (let i = 0; i < res.Data.Data.length; i++) {
                const element = res.Data.Data[i];
                element.WorkTime =
                  element.WorkTime.split(" ")[0] +
                  " " +
                  String(
                    String(
                      element.StartHour < 10
                        ? "0" + element.StartHour
                        : element.StartHour
                    ) +
                      ":" +
                      String(
                        element.StartMinute < 10
                          ? "0" + element.StartMinute
                          : element.StartMinute
                      ) +
                      "-" +
                      String(
                        element.EndHour < 10
                          ? "0" + element.EndHour
                          : element.EndHour
                      ) +
                      ":" +
                      String(
                        element.EndMinute < 10
                          ? "0" + element.EndMinute
                          : element.EndMinute
                      )
                  );
              }
              if (that.pageindex == 1) {
                that.AllCount = res.Data.AllCount;
              }
              that.tableData = res.Data.Data;
            }
          })
          .catch((err) => {
            console.log("====================================");
            console.log(err);
            console.log("====================================");
          });
      }
    },

    CopySuccessMessage() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    SuccessMessage() {
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    ErrorMessage() {
      this.$message({
        message: "刷新失败",
        type: "error",
      });
    },

    Lookip() {
      GetUrl().then((res) => {
        window.location.href = res.Data;
      });
    },
    add() {
      this.dialogVisible = true;
    },
  },
  created() {
    this.GetAllExamineLimitPageFn(); //分页获取所有审批信息
    this.GetAllStoreFn(); //获取所有门店
    this.GetAllTeachPlanFn();

    let arr = [];
    let arr2 = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;
    //获取当前年月日
    // Date();
    // {
    //   const nowDate = new Date();
    //   const date = {
    //     year: nowDate.getFullYear(),
    //     month: nowDate.getMonth() + 1,
    //     date: nowDate.getDate(),
    //   };
    //   const newmonth = date.month > 10 ? date.month : "0" + date.month;
    //   const day = date.date > 10 ? date.date : "0" + date.date;
    //   this.AddTrans.EndTime = date.year + "-" + newmonth + "-" + day;
    // }
  },
};
</script>

<style>
.indexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.box-property {
  width: 280px;
  height: 80px;
  box-shadow: 0px 2px 2px #d0d0d0;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.img-property {
  width: 70px;
  float: left;
}

.text-property {
  font-size: 20px;
  margin-left: 30px;
}

.text-data {
  font-size: 20px;
  margin-left: 40px;
  color: dodgerblue;
}

/* 鼠标移入表格不高亮 */

.table-tranparent.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}

.table-tranparent.el-table tbody tr:hover {
  background-color: #ffffff !important;
}
</style>
